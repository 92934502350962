<!-- System: STA
    Purpose: In dashboard project user can see his
          currently working on projects
-->
<template>
  <div>
    <!-- Projects Card  -->
    <v-card class="dashboard-boxes" elevation="15">
      <!-- Projects Image  -->
      <img
        :src="$api_url + 'storage/' + project.logo"
        @error="
          $event.target.src = require('../../assets/images/dashboard_icons/no_image.svg')
        "
        height="120"
        width="120"
        class="img-fluid proj-img mt-1"
      />
      <!-- Projects Name Tootip  -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card-title
            v-bind="attrs"
            v-on="on"
            class="dashboard-boxes-titles"
            >{{ trim_project(project.name) }}</v-card-title
          >
        </template>
        <span>{{ project.name }}</span>
      </v-tooltip>
      <!-- Projects Details  -->
      <v-card-text class="justify-center">
        <div>
          Logged Hours:
          <span v-if="project.minutes.hrs <= 9"
            >0{{ project.minutes.hrs }}</span
          >
          <span v-else>{{ project.minutes.hrs }}</span>
          <span>:</span>
          <span v-if="project.minutes.mins <= 9"
            >0{{ project.minutes.mins }}</span
          >
          <span v-else>{{ project.minutes.mins }}</span>
        </div>
        <div v-if="selectedCompanyUserStatus !== 'employee' && showPermission">
          No. of Employees: {{ project.employees }}
        </div>
        <div>Number of Tasks: {{ project.tasks_count }}</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardProject",
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the permission status
     * of that user
     */
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.dashboard) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  props: ["project"],
  methods: {
    /**
     * This function is resposible for triming
     * project name if it exceed from 16 digits.
     */
    trim_project(project_name) {
      return project_name.length > 10
        ? project_name.slice(0, 10) + "..."
        : project_name;
    },
  },
};
</script>

<style scoped>
.dashboard-boxes {
  height: 296px;
  width: 284px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 22px 40px -25px rgba(55, 72, 154, 0.45) !important;
}
.dashboard-boxes-titles {
  display: flow-root;
  font-size: 22px;
  font-weight: 500;
}
.widgets {
  display: flex;
  justify-content: left;
  width: 100%;
  flex-wrap: wrap;
}
.logged-hours {
  color: #36454f;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
}
.v-card__text.justify-center > div {
  text-align: center;
  display: block;
  font-weight: 500;
  font-size: 13px;
  padding: 4px;
}
</style>
